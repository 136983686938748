<template>
    <div class="d-flex flex-column">
        <div class="h1 mb-5">Приложение ОКО ВПР. Печать</div>
        <a
            v-for="(link, index) in links"
            :key="index"
            :href="link.url"
            download
            class="mb-5"
        >
            <v-btn 
                outlined
                height="40px"
                class="button-stylized-blue"
            >
                <v-icon color="white">{{ link.icon }}</v-icon>
                <span class="fs-16 fw-400 white--text ml-2">{{ link.text }}</span>
            </v-btn>
        </a>
    </div>
</template>

<script>
import { JOB_SERVER } from '@/servers'

export default {
    computed: {
        links () {
            return [
                { url: `${JOB_SERVER}/upload/apps/print/install_okovprprint_1.0.3.exe`, text: 'Скачать для Windows', icon: 'mdi-microsoft-windows' },
                { url: `${JOB_SERVER}/upload/apps/print/oko-vpr-print_1.0.3_amd64.deb`, text: 'Скачать для Астра Linux', icon: 'mdi-linux' },
                { url: `${JOB_SERVER}/upload/apps/print/oko-vpr-print-1.0.3.x86_64.rpm`, text: 'Скачать для РедОС и Alt Linux', icon: 'mdi-linux' }
            ]
        }
    }
}
</script>